import {
  DEFAULT_FONT,
  MOBILE_BREAKPOINT,
  assets,
  colors,
  colorsRGB,
} from "@util/constants";
import {
  SanityNutritionalInfo,
  SanityProductInfoModule,
  SanityProductModule,
} from "@graphql-types";

import Accordion from "react-collapsy";
import { BlocksContent } from "@global";
import { Container } from "@util/standard";
import React from "react";
import styled from "styled-components";
import { toPlainText } from "@util/helper";

const StyledAccordion = styled(Accordion)`
  .Accordion {
    border: none;
  }
  border: none;
  margin-bottom: 0;
  border-bottom: 1px solid ${colors.merlin};
  &:last-of-type {
    border-bottom: 0;
  }
  .Accordion__header {
    font-family: ${DEFAULT_FONT};
    background: ${colors.transparent};
    font-size: 15px;
    color: ${colors.merlin};
    padding: 0.4em 0;
    cursor: pointer;
    &:after {
      width: 14px;
      height: 7px;
      right: 18px;
      background: url(${assets.arrowDownDark}) no-repeat center;
      background-size: contain;
    }
  }

  .Accordion__body {
    padding: 0;
    font-size: 15px;
    > div {
      margin-bottom: 1em;
    }
    p {
      font-size: 15px;
      line-height: 1.5;
      &:first-of-type {
        margin-top: 0.4em;
      }
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .Accordion__header,
    .Accordion__body,
    .Accordion__body p {
      font-size: 14px;
    }
    .Accordion__header {
      padding: 0.2em 0;
    }
    .Accordion__body {
      > div {
        margin-bottom: 0.6em;
      }
    }
  }
`;

const Table = styled(Container)`
  flex-flow: row wrap;
  margin-top: 1em;
`;

const Tr = styled(Container)`
  width: 100%;
  flex-flow: row nowrap;
  border-bottom: 1px solid ${colorsRGB.merlin(0.3)};
  padding: 11px 0 14px;
  &:last-of-type {
    border-bottom: 0;
  }
`;

const Th = styled(Tr)`
  border-bottom: 1px solid ${colors.merlin};
  font-weight: 600;
  padding: 0 5px 11px 0;
`;

const Td = styled(Container)`
  width: 33.3333%;
`;

interface Props {
  main: SanityProductModule;
  info: SanityProductInfoModule;
  checkContainerHeight?: () => void;
  isGift?: boolean;
}

const AccordionTitle = (title: string) => {
  if (!title) return null;
  return (
    <Container>
      <p>{title}</p>
    </Container>
  );
};

const ProductDescription = ({
  main,
  info,
  checkContainerHeight,
  isGift,
}: Props) => {
  const nipInfo = info.nipInfo;
  const firstInfo = nipInfo?.firstInfo;
  const secondInfo = nipInfo?.secondInfo;
  const nipData = [
    ["Energy", "energy"],
    ["Protein", "protein"],
    ["- Gluten", "gluten"],
    ["Fat, Total", "fat"],
    ["- Saturated", "saturated"],
    ["Carbohydrate", "carbohydrate"],
    ["- Sugars", "sugars"],
    ["Sodium", "sodium"],
    ["Vitamin C", "vitaminC"],
    ["Dietary Fibre", "dietaryFibre"],
  ];

  return (
    <Container flexDirection="column">
      {toPlainText(main.productDirectionsForUse) && (
        <>
          <StyledAccordion
            title={main.productDirectionsForUseTitle ?? "Directions for use"}
            isOpen={false}
            onToggle={checkContainerHeight}
          >
            <Container flexDirection="column">
              <BlocksContent
                blocks={main.productDirectionsForUse?._rawBlocks}
              />
            </Container>
          </StyledAccordion>
        </>
      )}

      {/* Nutrition */}
      {(toPlainText(nipInfo?.nutritionalDescription) ||
        firstInfo ||
        secondInfo) && (
        <StyledAccordion
          title={AccordionTitle(`Nutritional Information`)}
          isOpen={false}
          onToggle={checkContainerHeight}
        >
          <Container flexDirection="column">
            <BlocksContent
              blocks={nipInfo?.nutritionalDescription?._rawBlocks}
            />
            {/* Table */}
            <Table>
              {/* Header */}
              <Th>
                <Td>Avg. Quantity</Td>
                <Td>{nipInfo?.firstTitle}</Td>
                <Td>{nipInfo?.secondTitle}</Td>
              </Th>
              {/* Data */}
              {nipData.map(row => {
                const col1 =
                  firstInfo && firstInfo[row[1] as keyof SanityNutritionalInfo];
                const col2 =
                  secondInfo &&
                  secondInfo[row[1] as keyof SanityNutritionalInfo];

                if (!col1 && !col2) return;

                return (
                  <Tr key={row[1]}>
                    <Td>{row[0]}</Td>
                    <Td>{col1}</Td>
                    <Td>{col2}</Td>
                  </Tr>
                );
              })}
            </Table>
            {/* <p>{nipInfo?.code}</p> */}
          </Container>
        </StyledAccordion>
      )}

      {/* Ingredients */}
      {toPlainText(info.ingredientsContent) && (
        <StyledAccordion
          title={AccordionTitle(info.ingredientsTitle as string)}
          isOpen={false}
          onToggle={checkContainerHeight}
        >
          <Container>
            <BlocksContent blocks={info.ingredientsContent?._rawBlocks} />
          </Container>
        </StyledAccordion>
      )}

      {/* More Information */}
      {toPlainText(info.moreInfoContent) && (
        <StyledAccordion
          title={AccordionTitle(info?.moreInfoTitle as string)}
          isOpen={false}
          onToggle={checkContainerHeight}
        >
          <Container>
            <BlocksContent blocks={info.moreInfoContent?._rawBlocks} />
          </Container>
        </StyledAccordion>
      )}
    </Container>
  );
};

export default ProductDescription;
