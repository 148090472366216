import React, { useState } from "react";
import styled from "styled-components";

import { Maybe, SanityStockist, SanityDelivery } from "@graphql-types";
import { Container, FlexGrid } from "@util/standard";
import {
  assets,
  colorsRGB,
  colors,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { BlocksContent } from "@global";

const StyledContainer = styled(Container)<{
  isHovered: boolean;
}>`
  position: relative;
  align-items: center;
  img {
    margin-right: 10px;
  }
  > span {
    margin-right: 14px;
    text-transform: capitalize;
  }
  svg {
    width: 19px;
    color: ${colorsRGB.merlin(0.15)};
  }
  ${({ isHovered }) =>
    isHovered &&
    `
    svg{
      color: ${colors.merlin};
    }
  `}
`;

const AbsoluteContainer = styled(Container)`
  position: absolute;
  width: 300px;
  top: -14px;
  left: calc(100% + 10px);
  background: ${colors.merlin};
  color: ${colors.white};
  padding: 1em 1.5em;
  z-index: 3;
  border-radius: 4px;
  font-size: 13px;
  box-sizing: border-box;

  &:before {
    content: "";
    position: absolute;
    top: 20px;
    left: -13px;
    width: 0;
    border-top: 10px solid ${colors.merlin};
    border-left: 10px solid ${colors.transparent};
    border-right: 10px solid ${colors.transparent};
    transform: rotate(90deg);
  }

  p,
  a {
    line-height: 1.4;
    font-size: 15px;
    letter-spacing: 0;
  }

  p {
    margin: 0;
  }

  a {
    color: ${colors.white};
    text-decoration: underline;
    margin-top: 1em;
    display: inline-block;
    cursor: pointer;
    white-space: normal;
    &:hover {
      opacity: 0.6;
    }
  }

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT + 140}px) {
    top: 35px;
    left: -10px;
    &:before {
      top: -7px;
      left: 26%;
      transform: rotate(180deg);
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    left: -40px;
    width: 180px;
    &:before {
      left: 40px;
    }
    &,
    p,
    a {
      font-size: 14px;
    }
  }
`;

const MoreInfoSvg = () => (
  <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle cx="9.5" cy="9.5" r="9.5" fill="currentColor" />
      <path
        d="M8.86133 6.93359V5.41016H10.5078V6.93359H8.86133ZM8.86133 14V7.77734H10.5078V14H8.86133Z"
        fill="white"
      />
    </g>
  </svg>
);

interface Props {
  stockists?: Maybe<Maybe<SanityStockist>[]>;
  delivery?: Maybe<SanityDelivery>;
}

const ProductStock = ({ stockists, delivery }: Props) => {
  const stockistsId = "stockists";
  const deliveryId = "delivery";
  const [hoverIndex, setHoverIndex] = useState<
    typeof stockistsId | typeof deliveryId | null
  >(null);

  return (
    <FlexGrid flexWrap="wrap" margin="0 0 30px" tabletMargin="0 0 10px">
      {stockists && stockists.length > 0 && (
        <Container>
          <StyledContainer
            onMouseEnter={() => setHoverIndex(stockistsId)}
            onMouseLeave={() => setHoverIndex(null)}
            isHovered={hoverIndex === stockistsId}
          >
            <img
              src={assets[stockistsId]}
              alt={stockistsId}
              width="27px"
              height="17px"
            />
            <span>{stockistsId}</span>
            <MoreInfoSvg />
            {hoverIndex === stockistsId && (
              <AbsoluteContainer>
                {stockists.map(stockist => {
                  if (!stockist) return;
                  return (
                    <Container key={stockist._key}>{stockist.title}</Container>
                  );
                })}
              </AbsoluteContainer>
            )}
          </StyledContainer>
        </Container>
      )}
      {delivery && (
        <Container>
          <StyledContainer
            onMouseEnter={() => setHoverIndex(deliveryId)}
            onMouseLeave={() => setHoverIndex(null)}
            isHovered={hoverIndex === deliveryId}
          >
            <img
              src={assets[deliveryId]}
              alt={deliveryId}
              width="25px"
              height="17px"
            />
            <span>{deliveryId}</span>
            <MoreInfoSvg />
            {hoverIndex === deliveryId && (
              <AbsoluteContainer>
                <BlocksContent
                  blocks={delivery.deliveryInfo?._rawBlocks}
                  textButton
                />
              </AbsoluteContainer>
            )}
          </StyledContainer>
        </Container>
      )}
    </FlexGrid>
  );
};

export default ProductStock;
